import React from 'react';
import { motion } from 'framer-motion';
import { FaGithub, FaLinkedin, FaAppStore } from 'react-icons/fa';
import personalConfig from '../config/personalConfig';

function Footer() {
  const { name, social } = personalConfig;

  return (
    <footer className="relative py-12 overflow-hidden">
      {/* Subtle gradient background */}
      <div className="absolute inset-0 pointer-events-none">
        <div 
          className="absolute w-[800px] h-[800px] rounded-full blur-[120px] 
                     -bottom-[600px] left-1/2 transform -translate-x-1/2 opacity-30"
          style={{ background: 'radial-gradient(circle, rgba(159,122,234,0.05) 0%, rgba(97,218,251,0.05) 100%)' }}
        />
      </div>

      <div className="container mx-auto px-6 relative z-10">
        <div className="flex flex-col items-center">
          {/* Social Links */}
          <div className="flex space-x-6 mb-8">
            <motion.a
              href={social.github}
              target="_blank"
              rel="noopener noreferrer"
              className="p-3 bg-[#1c1c3d]/50 rounded-lg hover:bg-accent-blue/20 transition-colors duration-300"
              whileHover={{ scale: 1.1, y: -2 }}
              whileTap={{ scale: 0.9 }}
            >
              <FaGithub className="w-5 h-5 text-gray-400 hover:text-accent-blue" />
            </motion.a>
            <motion.a
              href={social.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className="p-3 bg-[#1c1c3d]/50 rounded-lg hover:bg-accent-blue/20 transition-colors duration-300"
              whileHover={{ scale: 1.1, y: -2 }}
              whileTap={{ scale: 0.9 }}
            >
              <FaLinkedin className="w-5 h-5 text-gray-400 hover:text-accent-blue" />
            </motion.a>
            <motion.a
              href={social.appStore}
              target="_blank"
              rel="noopener noreferrer"
              className="p-3 bg-[#1c1c3d]/50 rounded-lg hover:bg-accent-blue/20 transition-colors duration-300"
              whileHover={{ scale: 1.1, y: -2 }}
              whileTap={{ scale: 0.9 }}
            >
              <FaAppStore className="w-5 h-5 text-gray-400 hover:text-accent-blue" />
            </motion.a>
          </div>

          {/* Copyright */}
          <p className="text-gray-500 text-sm">
            © {new Date().getFullYear()} {name}
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
