import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaGithub, FaAppStore, FaExternalLinkAlt } from 'react-icons/fa';
import personalConfig from '../config/personalConfig';

function Projects({ appStoreData = [] }) {
  const [hoveredId, setHoveredId] = useState(null);
  const [activeFilter, setActiveFilter] = useState('all');
  const { projects } = personalConfig;

  // Define filter options
  const filterOptions = [
    { id: 'all', label: 'All Projects' },
    { id: 'app-store', label: 'App Store' },
    { id: 'other', label: 'Other Projects' }
  ];

  // Combine and sort projects
  const allProjects = [
    ...projects,
    ...(appStoreData || []).map(app => ({
      id: `app-${app.trackId}`,
      appStore: true,
      title: app.trackName,
      description: app.description,
      image: app.artworkUrl512 || app.artworkUrl100,
      techStack: app.techStack || [],
      link: app.trackViewUrl,
      lastModified: new Date(app.currentVersionReleaseDate || app.releaseDate)
    }))
  ];

  const filteredProjects = allProjects.filter(project => {
    if (activeFilter === 'all') return true;
    if (activeFilter === 'app-store') return project.appStore;
    if (activeFilter === 'other') return !project.appStore;
    return true;
  });

  const cardClasses = "group relative bg-[#1c1c3d]/50 backdrop-blur-sm rounded-xl overflow-hidden border border-accent-blue/20 hover:border-accent-purple/40 transform hover:scale-[1.02] transition-all duration-300 flex flex-col h-full";
  const tagClasses = "px-3 py-1 text-sm bg-[#0c0c1d]/50 rounded-full text-accent-blue border border-accent-blue/20 transition-all duration-300";
  const buttonClasses = "px-6 py-2 rounded-lg transition-all duration-300";
  const linkClasses = "p-2 bg-[#0c0c1d]/50 rounded-lg hover:bg-accent-blue/20 transition-colors duration-300";

  return (
    <section id="projects" className="py-6">
      <div className="container mx-auto px-6">
        <motion.h2 
          className="text-4xl font-bold mb-4 text-center text-white"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Featured Work
        </motion.h2>

        {/* Filter Buttons */}
        <div className="flex justify-center gap-4 mb-12">
          {filterOptions.map((filter) => (
            <motion.button
              key={filter.id}
              onClick={() => setActiveFilter(filter.id)}
              className={`${buttonClasses} ${
                activeFilter === filter.id 
                  ? 'bg-gradient-to-r from-accent-blue to-accent-purple text-white' 
                  : 'bg-[#1c1c3d]/50 text-gray-300 hover:text-white'
              }`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {filter.label}
            </motion.button>
          ))}
        </div>

        {/* Projects Grid */}
        <div className="relative">
          <motion.div 
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
            layout="position"
          >
            <AnimatePresence initial={false}>
              {filteredProjects.map((project) => (
                <motion.div
                  key={project.id}
                  layout="position"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  whileHover={{ scale: 1.02 }}
                  transition={{ duration: 0.3 }}
                  className={cardClasses}
                >
                  {/* Project Image */}
                  <div className="relative w-full h-48 overflow-hidden">
                    <img
                      src={project.image}
                      alt={project.title}
                      className="w-full h-full object-cover"
                    />
                    
                    {/* App Store Badge */}
                    {project.appStore && (
                      <div className="absolute top-4 right-4 bg-gradient-to-r from-accent-blue/80 to-accent-purple/80 
                                  backdrop-blur-sm px-4 py-2 rounded-full
                                  flex items-center gap-2 shadow-lg z-20">
                        <FaAppStore className="text-white w-5 h-5" />
                        <span className="text-sm font-medium text-white">Published on App Store</span>
                      </div>
                    )}
                  </div>

                  {/* Project Content */}
                  <div className="p-6 flex flex-col flex-grow">
                    <div className="flex-grow">
                      <h3 className="text-xl font-bold text-white mb-2">
                        {project.title}
                      </h3>
                      <p className="text-gray-300 mb-4 line-clamp-2">
                        {project.description}
                      </p>

                      {/* Tech Stack */}
                      <div className="flex flex-wrap gap-2 mb-4">
                        {project.techStack.map((tech, index) => (
                          <span
                            key={index}
                            className={tagClasses}
                          >
                            {tech}
                          </span>
                        ))}
                      </div>
                    </div>

                    {/* Project Links */}
                    <div className="flex gap-4 mt-auto pt-4">
                      {project.appStore && (
                        <a
                          href={project.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`${linkClasses} flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-accent-blue to-accent-purple rounded-lg text-white font-medium shadow-lg hover:shadow-accent-purple/50`}
                        >
                          <FaAppStore className="w-5 h-5" />
                          <span>View on App Store</span>
                        </a>
                      )}
                      {project.github && (
                        <a
                          href={project.github}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={linkClasses}
                        >
                          <FaGithub className="w-5 h-5 text-gray-400 group-hover:text-accent-blue" />
                        </a>
                      )}
                      {project.link && !project.appStore && (
                        <a
                          href={project.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={linkClasses}
                        >
                          <FaExternalLinkAlt className="w-4 h-4 text-gray-400 group-hover:text-accent-blue" />
                        </a>
                      )}
                    </div>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        </div>
      </div>
    </section>
  );
}

export default Projects;
