import React from 'react';
import { motion } from 'framer-motion';
import { FaCode, FaRobot, FaChartBar, FaCubes, FaApple, FaNodeJs, FaServer, FaDatabase } from 'react-icons/fa';
import personalConfig from '../config/personalConfig';

const iconMap = {
  FaCode,
  FaRobot,
  FaChartBar,
  FaCubes,
  FaApple,
  FaNodeJs,
  FaServer,
  FaDatabase,
};

function Skills() {
  const { skills } = personalConfig;

  const cardClasses = "bg-[#1c1c3d]/50 backdrop-blur-sm rounded-xl p-6 border border-accent-blue/20 hover:border-accent-purple/40 transform hover:scale-[1.02] transition-all duration-300";

  return (
    <section id="skills" className="py-6">
      <div className="container mx-auto px-6">
        <motion.h2 
          className="text-4xl font-bold mb-4 text-center text-white"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Technical Skills
        </motion.h2>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {skills.map((skill, index) => {
            const Icon = iconMap[skill.icon];
            
            return (
              <motion.div
                key={skill.name}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                whileHover={{ scale: 1.02 }}
                transition={{ duration: 0.3 }}
                className={cardClasses}
              >
                <div className="flex flex-col items-center text-center gap-4">
                  <div className="p-4 rounded-lg bg-gradient-to-r from-accent-blue/20 to-accent-purple/20">
                    {Icon && <Icon className="w-8 h-8 text-accent-blue group-hover:text-accent-purple transition-colors duration-300" />}
                  </div>
                  <h3 className="text-lg font-semibold text-white">{skill.name}</h3>
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Skills;
