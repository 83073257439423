import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaArrowUp } from 'react-icons/fa';

function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.button
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 z-50 p-4 rounded-lg
                   bg-[#1c1c3d]/80 backdrop-blur-sm
                   border border-accent-blue/20 hover:border-accent-purple/40
                   shadow-lg hover:shadow-accent-purple/25
                   transition-all duration-300 group"
          whileHover={{ scale: 1.1, y: -2 }}
          whileTap={{ scale: 0.9 }}
        >
          <FaArrowUp className="w-5 h-5 text-gray-400 group-hover:text-accent-blue 
                               transition-colors duration-300" />
          
          <div className="absolute inset-0 rounded-lg opacity-0 group-hover:opacity-100
                        bg-gradient-to-r from-accent-blue/10 to-accent-purple/10
                        transition-opacity duration-300" />
        </motion.button>
      )}
    </AnimatePresence>
  );
}

export default ScrollToTop;
