import React from 'react';
import { motion } from 'framer-motion';
import { FaGraduationCap, FaBriefcase, FaAppStore, FaExternalLinkAlt, FaCertificate } from 'react-icons/fa';
import personalConfig from '../config/personalConfig';

function Resume() {
  const { experience, education, certifications } = personalConfig;

  const cardClasses = "bg-[#1c1c3d]/50 backdrop-blur-sm rounded-xl p-8 border border-accent-blue/20 hover:border-accent-purple/40 transform hover:scale-[1.02] transition-all duration-300";
  const nestedCardClasses = "bg-[#1c1c3d]/50 backdrop-blur-sm rounded-xl p-6 border border-accent-blue/20 transition-all duration-300";
  const tagClasses = "px-3 py-1 text-sm bg-[#0c0c1d]/50 rounded-full text-accent-blue border border-accent-blue/20 transition-all duration-300";

  return (
    <section id="resume" className="py-16">
      <div className="container mx-auto px-6">
        <motion.h2 
          className="text-4xl font-bold mb-16 text-center text-white"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Professional Journey
        </motion.h2>

        {/* Education Card */}
        <motion.div className="mb-16">
          <div className="flex items-center mb-8">
            <div className="p-3 rounded-lg bg-gradient-to-r from-accent-blue/20 to-accent-purple/20 mr-4">
              <FaGraduationCap className="w-6 h-6 text-accent-blue" />
            </div>
            <h3 className="text-2xl font-bold text-white">Education</h3>
          </div>

          <div className={cardClasses}>
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
              <div className="flex items-center gap-4">
                {education[0].schoolLogo && (
                  <div className="w-14 h-14 rounded-xl overflow-hidden border border-accent-blue/20 bg-[#0c0c1d]/50 flex items-center justify-center">
                    <img
                      src={education[0].schoolLogo}
                      alt={education[0].school}
                      className="w-full h-full object-cover"
                    />
                  </div>
                )}
                <div>
                  <h4 className="text-xl font-bold text-white">{education[0].degree}</h4>
                  <p className="text-accent-blue mt-1">{education[0].school}</p>
                  <div className="flex flex-wrap gap-4 mt-2">
                    <span className="text-accent-purple">
                      GPA: {education[0].gpa}
                    </span>
                    <span className="text-accent-purple">
                      Minor: {education[0].minor}
                    </span>
                  </div>
                </div>
              </div>
              <span className="mt-2 md:mt-0 px-4 py-1 bg-[#0c0c1d]/50 rounded-full text-accent-purple">
                {education[0].year}
              </span>
            </div>
            <p className="text-gray-300">{education[0].description}</p>
          </div>
        </motion.div>

        {/* Experience Section */}
        <motion.div className="mb-16">
          <div className="flex items-center mb-8">
            <div className="p-3 rounded-lg bg-gradient-to-r from-accent-blue/20 to-accent-purple/20 mr-4">
              <FaBriefcase className="w-6 h-6 text-accent-purple" />
            </div>
            <h3 className="text-2xl font-bold text-white">Experience</h3>
          </div>

          <div className="space-y-6">
            {experience.map((exp) => (
              <motion.div
                key={exp.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                whileHover={{ scale: 1.02 }}
                transition={{ duration: 0.3 }}
                className={cardClasses}
              >
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
                  <div className="flex items-center gap-4">
                    {exp.companyLogo && (
                      <div className="w-14 h-14 rounded-xl overflow-hidden border border-accent-blue/20 bg-[#0c0c1d]/50 flex items-center justify-center">
                        <img
                          src={exp.companyLogo}
                          alt={exp.company}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    )}
                    <div>
                      <h4 className="text-xl font-bold text-white">{exp.title}</h4>
                      <p className="text-accent-purple mt-1">{exp.company}</p>
                    </div>
                  </div>
                  <span className="mt-2 md:mt-0 px-4 py-1 bg-[#0c0c1d]/50 rounded-full text-accent-blue">
                    {exp.year}
                  </span>
                </div>
                
                <p className="text-gray-300 mb-4">{exp.description}</p>

                {/* Key Achievements Section */}
                <div className="space-y-2 mb-6">
                  <h5 className="text-sm font-semibold text-accent-blue mb-3">Key Achievements</h5>
                  <ul className="space-y-2">
                    {exp.achievements.map((achievement, index) => (
                      <motion.li
                        key={index}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.3, delay: index * 0.1 }}
                        className="flex items-start gap-3 text-gray-300 hover:text-white 
                                   transform hover:scale-[1.01] transition-all duration-300"
                      >
                        <span className="text-accent-purple mt-1.5">•</span>
                        <span>{achievement}</span>
                      </motion.li>
                    ))}
                  </ul>
                </div>

                {/* Project Link Section - Only show if projectLink exists */}
                {exp.projectLink && (
                  <div className="mt-6 pt-6 border-t border-accent-blue/20">
                    <h5 className="text-sm font-semibold text-accent-blue mb-4">
                      {exp.company}'s {exp.projectLink.name}
                    </h5>
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      className={nestedCardClasses}
                    >
                      <div className="flex items-center gap-4">
                        <div className="flex-grow">
                          <p className="text-sm text-gray-400">{exp.projectLink.description}</p>
                        </div>
                        <motion.a
                          href={exp.projectLink.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-accent-blue to-accent-purple 
                                   rounded-lg text-white font-medium shadow-lg hover:shadow-accent-purple/50
                                   transition-all duration-300 group"
                          whileHover={{ scale: 1.05, y: -2 }}
                          whileTap={{ scale: 0.95 }}
                        >
                          <FaExternalLinkAlt className="w-4 h-4" />
                          <span>Read More</span>
                        </motion.a>
                      </div>
                    </motion.div>
                  </div>
                )}

                {/* Apps Section - Only show if apps exist */}
                {exp.apps && exp.apps.length > 0 && (
                  <div className="mt-6 pt-6 border-t border-accent-blue/20">
                    <h5 className="text-sm font-semibold text-accent-blue mb-4">Published Applications</h5>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {exp.apps.map((app, index) => (
                        <motion.div
                          key={index}
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: index * 0.1 }}
                          className={nestedCardClasses}
                        >
                          <div className="flex items-center gap-4">
                            {app.icon && (
                              <img
                                src={app.icon}
                                alt={`${app.name} icon`}
                                className="w-12 h-12 rounded-xl"
                              />
                            )}
                            <div className="flex-grow">
                              <h6 className="text-white font-medium">{app.name}</h6>
                              <p className="text-sm text-gray-400">{app.description}</p>
                            </div>
                            <motion.a
                              href={app.appStoreLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="p-2 bg-[#1c1c3d]/50 rounded-lg hover:bg-accent-blue/20 
                                       transition-colors duration-300 group"
                              whileHover={{ scale: 1.1, y: -2 }}
                              whileTap={{ scale: 0.9 }}
                            >
                              <FaAppStore className="w-5 h-5 text-gray-400 group-hover:text-accent-blue" />
                            </motion.a>
                          </div>
                        </motion.div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Technologies Used */}
                <div className="flex flex-wrap gap-2 mt-6">
                  {exp.technologies.map((tech, index) => (
                    <span
                      key={index}
                      className={tagClasses}
                    >
                      {tech}
                    </span>
                  ))}
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Certifications Section */}
        <motion.div>
          <div className="flex items-center mb-8">
            <div className="p-3 rounded-lg bg-gradient-to-r from-accent-blue/20 to-accent-purple/20 mr-4">
              <FaCertificate className="w-6 h-6 text-accent-blue" />
            </div>
            <h3 className="text-2xl font-bold text-white">Certifications</h3>
          </div>

          <div className="space-y-6">
            {certifications.map((cert) => (
              <motion.div
                key={cert.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                whileHover={{ scale: 1.02 }}
                transition={{ duration: 0.3 }}
                className={cardClasses}
              >
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
                  <div className="flex items-center gap-4">
                    {cert.issuerLogo && (
                      <div className="w-14 h-14 rounded-xl overflow-hidden border border-accent-blue/20 bg-[#0c0c1d]/50 flex items-center justify-center">
                        <img
                          src={cert.issuerLogo}
                          alt={cert.issuer}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    )}
                    <div>
                      <h4 className="text-xl font-bold text-white">{cert.title}</h4>
                      <p className="text-accent-purple mt-1">{cert.issuer}</p>
                      <p className="text-sm text-gray-400 mt-1">Certificate ID: {cert.certNumber}</p>
                    </div>
                  </div>
                  <span className="mt-2 md:mt-0 px-4 py-1 bg-[#0c0c1d]/50 rounded-full text-accent-blue">
                    {cert.date}
                  </span>
                </div>

                <p className="text-gray-300 mb-4">{cert.description}</p>

                {/* Skills Learned */}
                <div className="space-y-2">
                  <h5 className="text-sm font-semibold text-accent-blue mb-3">Skills Covered</h5>
                  <div className="flex flex-wrap gap-2">
                    {cert.skills.map((skill, index) => (
                      <span
                        key={index}
                        className={tagClasses}
                      >
                        {skill}
                      </span>
                    ))}
                  </div>
                </div>

                {/* View Certificate Link */}
                <motion.a
                  href={cert.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center gap-2 px-4 py-2 mt-4 bg-gradient-to-r from-accent-blue/20 to-accent-purple/20 
                           rounded-lg text-white hover:from-accent-blue/30 hover:to-accent-purple/30
                           transition-all duration-300"
                  whileHover={{ scale: 1.05, y: -2 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <FaCertificate className="w-4 h-4" />
                  <span>View Certificate</span>
                </motion.a>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
}

export default Resume;
