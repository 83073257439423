// Helper function for backend URL
const getBackendUrl = () => {
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  return isLocalhost ? 'http://localhost:8000' : 'https://spencergoldberg-ceb11fcb30dc.herokuapp.com';
};

const config = {
  // Site Meta
  siteTitle: "Spencer Goldberg - Portfolio",
  siteDescription: "Full-Stack Developer, AI Engineer, and Data Scientist Portfolio",
  siteKeywords: "developer, engineer, AI, data science, portfolio",

  // Personal Information
  name: "Spencer Goldberg",
  title: "Full-Stack | AI Engineer | Data Scientist",
  subtitle: "Computer Science Student at Florida Atlantic University",
  location: "Boca Raton, Florida, United States",
  email: "spencergoldberg2@gmail.com",
  profileImage: "https://lh3.googleusercontent.com/a/ACg8ocKEKntk463HNatRGZPeV7nsbKEWA_c3yHK_UkhfyKeHlyXmCXIh=s576-c-no",

  // Social Links
  social: {
    github: "https://github.com/spencergoldberg1",
    linkedin: "https://www.linkedin.com/in/spencermgoldberg/",
    appStore: "https://apps.apple.com/us/developer/spencer-goldberg/id1620790979?app=itunes",
  },

  // API Configuration
  api: {
    backend: getBackendUrl(),
    get appStore() {
      return `${this.backend}/api/apps`;
    },
  },

  // Navigation Configuration
  navigation: [
    { id: 'home', label: 'Home' },
    { id: 'resume', label: 'Resume' },
    { id: 'projects', label: 'Projects' },
    { id: 'skills', label: 'Skills' },
  ],

  // Skills Configuration
  skills: [
    { name: 'Full-Stack Development', icon: 'FaCode' },
    { name: 'AI Engineering', icon: 'FaRobot' },
    { name: 'Data Science', icon: 'FaChartBar' },
    { name: 'Web3 & Blockchain', icon: 'FaCubes' },
    { name: 'iOS Development', icon: 'FaApple' },
    { name: 'Node.js', icon: 'FaNodeJs' },
    { name: 'API Development', icon: 'FaServer' },
    { name: 'Firebase', icon: 'FaDatabase' },
  ],

  // Experience Configuration
  experience: [
    {
      id: 1,
      year: 'Jan 2024 - Present',
      title: 'Full Stack Developer',
      company: 'Web3 Enabler',
      companyLogo: '/images/web3enabler-logo.png',
      description: 'Working on blockchain and Web3 technologies in a hybrid work environment.',
      achievements: [
        'Developed robust API endpoints on Heroku to enable seamless wallet connections and real-time blockchain data integration within Salesforce CRM',
        'Engineered secure, scalable solutions to map blockchain transactions (EVM, UTXO, and others) directly into Salesforce workflows, enhancing seamless worldwide transactions',
        'Leveraged Heroku, Express.js, and EJS templating to build efficient backend services that support various blockchain types and streamline data integration and user interfaces',
        'Conducted comprehensive learning and implementation across diverse blockchain networks, including Ethereum Virtual Machine (EVM) and Unspent Transaction Output (UTXO) structures',
        'Optimized API performance and ensured high availability, contributing to improved data synchronization and user experience in the Salesforce ecosystem'
      ],
      technologies: ['Salesforce', 'Express', 'API Development', 'Heroku', 'Crypto API','HTML', 'CSS'],
      projectLink: {
        name: "Blockchain Payments",
        url: "https://web3enabler.com",
        description: "Bringing blockchain payments and transaction data into Salesforce"
      }
    },
    {
      id: 2,
      year: 'Jun 2023 - Jul 2023',
      title: 'Full-stack Developer (Internship)',
      company: 'Olive Diagnostics',
      companyLogo: '/images/olive-logo.png',
      description: 'Built and deployed a user-friendly iOS app using SwiftUI and integrated it with Cloud Firestore.',
      achievements: [
        'Worked to develop a fullstack web app that allows nurses to filter their patients by various search criteria over diﬀerent time periods',
        'Conducted QA in order to create robust, reusable functions to be used for filtering the patients',
        'Integrated both APIs and databases like Sendgrid messaging platform and Gelen database to fetch patients',
        'Used CSS to design and style clean and modern user interactive user interfaces'
      ],
      technologies: ['Express', 'API', 'Data Science', 'Business Dashboard']
    },
    {
      id: 3,
      year: 'Oct 2021 - May 2023',
      title: 'Software Engineer (Internship)',
      company: 'Cocobolo Group',
      companyLogo: '/images/cocobolo-logo.png',
      description: 'Worked on various projects using React Native, Firebase, and GraphQL. Led development of two successful mobile applications published on the App Store.',
      achievements: [
        'Attended daily scrum meetings to maintain progress andproductivity',
        'Utilized Jira to create and work on assigned tickets to produceand develop robust code in SwiftUI',
        'Lead team to write and deploy scalable Firebase CloudFunctions using all backend technologies and APIs',
        'Headed team with creating a business dashboard that is usedto display all statistics about guests and servers to analyze productivity and trends',
        'Integrated APIs such as Google Places and Stripe Payments for tipping capabilities from a guest to a server'
      ],
      technologies: ['SwiftUI', 'Firebase', 'Node.js', 'Google Cloud', 'UI/UX Design'],
      apps: [
        {
          name: "Perfecti Guest",
          description: "A social media platform for musicians to connect and collaborate",
          appStoreLink: "https://apps.apple.com/us/app/perfecti-guest/id6462482435",
          icon: "https://is1-ssl.mzstatic.com/image/thumb/Purple221/v4/e7/c0/79/e7c07984-9f99-e726-274f-e8e57ef61d14/AppIcon-0-1x_U007emarketing-0-10-0-85-220-0.png/60x60bb.jpg"
        },
        {
          name: "Perfecti Server",
          description: "Real-time messaging app for music industry professionals",
          appStoreLink: "https://apps.apple.com/us/app/perfecti-server/id6466302265",
          icon: "https://is1-ssl.mzstatic.com/image/thumb/Purple211/v4/22/f4/b8/22f4b83a-608f-6748-f8b2-39695d635817/AppIcon-0-0-1x_U007emarketing-0-10-0-85-220.png/60x60bb.jpg"
        }
      ]
    },
  ],

  // Education Configuration
  education: [
    {
      id: 1,
      year: 'Jan 2022 - May 2025',
      degree: 'Bachelor of Science - Data Analytics, Computer Science',
      school: 'Florida Atlantic University',
      schoolLogo: '/images/fau-logo.png',
      gpa: '3.94',
      minor: 'Business Analytics',
      description: 'Senior grade. Courses include data structures, algorithms, databases, and machine learning.',
    }
  ],

  // Projects Configuration
  projects: [
    {
      id: 1,
      title: "Restify",
      description: "A streamlined API testing platform similar to Postman, designed for developers to test REST API endpoints. Features include parameter customization, request history tracking, and response visualization. Built with a focus on user experience and efficient workflow.",
      image: 'images/restify.jpg',
      techStack: ['React', 'Firebase', 'Cloud Firestore'],
      appStore: false,
      lastModified: new Date(2024, 0, 15),
    },
    {
      id: 2,
      title: "Easy Polling",
      description: "An innovative polling platform powered by OpenAI that enables users to create AI-suggested polls and participate in active community polls. The application leverages Supabase for real-time updates and secure data management, providing a seamless voting experience.",
      image: 'images/polling.jpg',
      techStack: ['React', 'Supabase', 'OpenAI API', 'TailwindCSS'],
      link: "https://animated-ganache-63c5b2.netlify.app",
      appStore: false,
      lastModified: new Date(2024, 1, 1),
    },
    {
      id: 3,
      title: "Face Sense",
      description: "An emotion recognition research project implementing AlexNet architecture for facial expression analysis. This study project demonstrates deep learning capabilities in real-time emotion detection, processing facial features to identify and classify various emotional states.",
      image: 'images/facesense.jpg',
      techStack: ['Python', 'PyTorch', 'AlexNet', 'OpenCV'],
      github: "https://github.com/spencergoldberg1/FaceSense.git",
      appStore: false,
      lastModified: new Date(2023, 11, 15),
    },
    {
      id: 4,
      title: "Wallet Connect",
      description: "An emotion recognition research project implementing AlexNet architecture for facial expression analysis. This study project demonstrates deep learning capabilities in real-time emotion detection, processing facial features to identify and classify various emotional states.",
      image: 'images/walletconnect.jpg',
      techStack: ['Express', 'Web3', 'HTML', 'CSS', 'API'],
      github: "https://github.com/spencergoldberg1/Web3Enabler-WalletConnect.git",
      appStore: false,
      lastModified: new Date(2024, 3, 15),
    }
  ],

  // Stats for Hero section
  stats: {
    experience: "4+ Years",
    projects: "10+"
  },

  certifications: [
    {
      id: 1,
      title: "Google Cybersecurity Professional Certificate",
      issuer: "Google",
      issuerLogo: "https://e7.pngegg.com/pngimages/63/1016/png-clipart-google-logo-google-logo-g-suite-chrome-text-logo.png",
      date: "February 2024",
      certNumber: "XYZ123456789",
      link: "https://www.coursera.org/account/accomplishments/professional-cert/...",
      description: "Comprehensive training in cybersecurity tools, security frameworks, and hands-on experience with security audits, risk assessments, and security protocols.",
      skills: [
        "Security Information and Event Management (SIEM) tools",
        "Network Security",
        "Python Security",
        "Linux Security"
      ]
    }
  ],
};

export default config;
