import React from 'react';
import { motion } from 'framer-motion';
import { FaGithub, FaLinkedin, FaAppStore, FaArrowDown } from 'react-icons/fa';
import personalConfig from '../config/personalConfig';

function Hero() {
  const { name, title, subtitle, profileImage, longBio, social, stats } = personalConfig;

  const scrollToProjects = (e) => {
    e.preventDefault();
    const projectsSection = document.getElementById('projects');
    if (projectsSection) {
      projectsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <section className="min-h-screen flex items-center justify-center py-6">
      <motion.div
        className="container mx-auto px-6 relative z-10 pt-16"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          {/* Left Column - Content */}
          <motion.div className="space-y-6">
            <motion.div variants={itemVariants} className="space-y-4">
              <span className="inline-block px-4 py-2 bg-accent-purple/10 text-accent-purple rounded-full text-sm font-medium">
                {title}
              </span>
              <h1 className="text-4xl lg:text-5xl font-bold text-white leading-tight">
                Hi, I'm{' '}
                <span className="bg-gradient-to-r from-accent-blue to-accent-purple bg-clip-text text-transparent">
                  {name}
                </span>
              </h1>
              <h2 className="text-xl lg:text-2xl text-gray-300 font-light">
                {subtitle}
              </h2>
            </motion.div>

            <motion.p 
              variants={itemVariants}
              className="text-gray-300 text-lg leading-relaxed max-w-2xl"
            >
              {longBio}
            </motion.p>

            {/* CTA Buttons */}
            <motion.div variants={itemVariants} className="flex flex-wrap gap-4">
              <button
                onClick={scrollToProjects}
                className="px-6 py-2.5 bg-gradient-to-r from-accent-blue to-accent-purple rounded-lg
                         text-white font-semibold hover:opacity-90 transition-all duration-300
                         shadow-lg hover:shadow-accent-purple/50 transform hover:translate-y-[-2px]"
              >
                View My Work
              </button>
              <a
                href={`mailto:${personalConfig.email}`}
                className="px-6 py-2.5 bg-[#1c1c3d] border border-accent-blue/30 rounded-lg
                         text-white font-semibold hover:border-accent-purple transition-all duration-300
                         transform hover:translate-y-[-2px]"
              >
                Get in Touch
              </a>
            </motion.div>

            {/* Social Links */}
            <motion.div variants={itemVariants} className="flex items-center gap-6">
              <a
                href={social.github}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-accent-blue transition-all duration-300 transform hover:scale-110"
              >
                <FaGithub className="w-6 h-6" />
              </a>
              <a
                href={social.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-accent-blue transition-all duration-300 transform hover:scale-110"
              >
                <FaLinkedin className="w-6 h-6" />
              </a>
              <a
                href={social.appStore}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-accent-blue transition-all duration-300 transform hover:scale-110"
              >
                <FaAppStore className="w-6 h-6" />
              </a>
            </motion.div>
          </motion.div>

          {/* Right Column - Profile Image & Decorative Elements */}
          <motion.div 
            variants={itemVariants}
            className="relative order-first lg:order-last lg:mt-0"
          >
            <div className="relative max-w-md mx-auto">
              <motion.div
                className="relative rounded-2xl overflow-hidden border-4 border-[#1c1c3d] bg-[#1c1c3d]"
                whileHover={{ scale: 1.02 }}
                transition={{ duration: 0.3 }}
              >
                <img
                  src={profileImage}
                  alt={name}
                  className="w-full aspect-square object-cover rounded-2xl"
                />

                {/* Stats cards */}
                <motion.div
                  className="absolute top-4 right-4 bg-[#1c1c3d]/70 backdrop-blur-sm p-3 rounded-xl border border-accent-blue/10"
                  whileHover={{ scale: 1.05 }}
                >
                  <p className="text-accent-blue font-semibold">{stats.experience}</p>
                  <p className="text-sm text-gray-300">Experience</p>
                </motion.div>

                {/* Commented out Projects Stats */}
                {/* <motion.div
                  className="absolute bottom-4 left-4 bg-[#1c1c3d]/70 backdrop-blur-sm p-3 rounded-xl border border-accent-purple/10"
                  whileHover={{ scale: 1.05 }}
                >
                  <p className="text-accent-purple font-semibold">{stats.projects}</p>
                  <p className="text-sm text-gray-300">Projects</p>
                </motion.div> */}
              </motion.div>
            </div>
          </motion.div>
        </div>

        {/* Scroll Indicator */}
        <motion.div
          className="absolute bottom-4 left-1/2 transform -translate-x-1/2"
          animate={{ y: [0, 10, 0] }}
          transition={{ duration: 1.5, repeat: Infinity }}
        >
          <FaArrowDown className="text-accent-blue/30 w-5 h-5" />
        </motion.div>
      </motion.div>
    </section>
  );
}

export default Hero;
