import React from 'react';
import { motion } from 'framer-motion';
import { FaCode } from 'react-icons/fa';

function LoadingScreen({ isLoading, onAnimationComplete }) {
  return (
    <motion.div
      className="fixed inset-0 z-50 flex items-center justify-center bg-[#0c0c1d]"
      initial={{ opacity: 1 }}
      animate={{ 
        opacity: isLoading ? 1 : 0,
        transitionEnd: {
          display: isLoading ? "flex" : "none",
        }
      }}
      transition={{ duration: 1.2, ease: "easeInOut" }}
      onAnimationComplete={() => {
        if (!isLoading) {
          onAnimationComplete();
        }
      }}
    >
      <motion.div
        initial={{ scale: 0.2, opacity: 0 }}
        animate={{ 
          scale: isLoading ? 1 : 1.5,
          opacity: isLoading ? 1 : 0,
          rotate: isLoading ? 360 : 0
        }}
        transition={{ 
          duration: 1,
          ease: [0.6, -0.05, 0.01, 0.99],
          rotate: { duration: 1.5 }
        }}
        className="relative"
      >
        <FaCode className="w-24 h-24 text-accent-blue" />
        
        {/* Animated rings */}
        <motion.div
          className="absolute inset-0 border-4 border-accent-blue/30 rounded-full -m-6"
          animate={{ 
            scale: [1, 1.2, 1],
            rotate: [0, 180, 360],
            opacity: [0.3, 0.5, 0.3]
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "reverse"
          }}
        />
        
        {/* Second ring with different animation timing */}
        <motion.div
          className="absolute inset-0 border-4 border-accent-purple/30 rounded-full -m-6"
          animate={{ 
            scale: [1.1, 1.3, 1.1],
            rotate: [180, 360, 180],
            opacity: [0.3, 0.5, 0.3]
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "reverse",
            delay: 0.5
          }}
        />

        {/* Subtle glow effect */}
        <motion.div
          className="absolute inset-0 bg-gradient-to-r from-accent-blue/20 to-accent-purple/20 blur-3xl -z-10"
          animate={{ 
            scale: [1, 1.2, 1],
            opacity: [0.3, 0.5, 0.3]
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            repeat: Infinity
          }}
        />
      </motion.div>
    </motion.div>
  );
}

export default LoadingScreen;