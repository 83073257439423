import React, { useState, useEffect } from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Resume from './components/Resume';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import personalConfig from './config/personalConfig';

// Initialize Firebase using config from personalConfig
const firebaseConfig = {
  apiKey: "AIzaSyAHQdXWOWep_cwaSx7147OQFJjO136RKnc",
  authDomain: "spencergoldberg-e7746.firebaseapp.com",
  projectId: "spencergoldberg-e7746",
  storageBucket: "spencergoldberg-e7746.appspot.com",
  messagingSenderId: "340966486949",
  appId: "1:340966486949:web:e436f7a4e7f0b25b27ee4c",
  measurementId: "G-D398KTWE6T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [appStoreData, setAppStoreData] = useState([]);
  const [showContent, setShowContent] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  // Preload profile image
  useEffect(() => {
    const profileImage = new Image();
    profileImage.src = personalConfig.profileImage;
    profileImage.onload = () => {
      setImageLoaded(true);
    };
    profileImage.onerror = () => {
      console.error('Failed to load profile image');
      setImageLoaded(true); // Continue loading site even if image fails
    };
  }, []);

  useEffect(() => {
    const fetchAppStoreData = async () => {
      try {
        const response = await fetch(personalConfig.api.appStore);
        const data = await response.json();
        setAppStoreData(data);
        
        // Log successful data fetch
        logEvent(analytics, 'app_store_data_fetch', {
          status: 'success',
          apps_count: data.length,
          timestamp: new Date().toISOString()
        });
      } catch (error) {
        console.error('Error fetching App Store data:', error);
        setAppStoreData([]);
        
        // Log failed data fetch
        logEvent(analytics, 'app_store_data_fetch', {
          status: 'error',
          error_message: error.message,
          timestamp: new Date().toISOString()
        });
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    };

    // Only start fetching app data once image is loaded
    if (imageLoaded) {
      fetchAppStoreData();
    }
  }, [imageLoaded]);

  const handleLoadingComplete = () => {
    setShowContent(true);
    logEvent(analytics, 'loading_complete', {
      time_to_load: Date.now(),
      timestamp: new Date().toISOString()
    });
  };

  // Show loading screen until both image is loaded and app data is fetched
  const isFullyLoaded = !isLoading && imageLoaded;

  return (
    <>
      <LoadingScreen 
        isLoading={!isFullyLoaded} 
        onAnimationComplete={handleLoadingComplete}
      />
      
      {showContent && (
        <div className="relative min-h-screen bg-[#0c0c1d] text-white font-sans">
          <div className="fixed inset-0 pointer-events-none">
            <div className="absolute inset-0 bg-gradient-to-b from-[#0c0c1d] via-[#0c0c1d] to-[#0f0f23]" />
            <div className="absolute w-[1000px] h-[1000px] bg-accent-purple/5 rounded-full blur-3xl -top-[400px] -right-[400px] animate-pulse" />
            <div className="absolute w-[1000px] h-[1000px] bg-accent-blue/5 rounded-full blur-3xl -bottom-[400px] -left-[400px] animate-pulse" />
          </div>

          <div className="relative z-10">
            <Navbar />
            <main className="pt-20">
              <Hero />
              <div className="space-y-8">
                <Resume />
                <Projects appStoreData={appStoreData || []} />
                <Skills />
              </div>
            </main>
            <Footer />
            <ScrollToTop />
          </div>
        </div>
      )}
    </>
  );
}

export default App;
